import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SeoComponent from '../components/SeoComponent'
import LayoutComponent from '../components/LayoutComponent'
import NumberFormat from 'react-number-format'
import Img from 'gatsby-image'

const BesteKredittkort = props => {

  const title = 'Beste kredittkort i Norge (2021)'

  const data = useStaticQuery(graphql`
        query {
          data: markdownRemark(fileAbsolutePath: {regex: "/(kredittkort-faq.md)/"}) {
            id
            html
            frontmatter {
              image
            }
          }
          allCardsJson {
            edges {
              node {
                beskrivelse
                eksempel
                maks
                navn
                anbefalt
                rente
                betalingsutsettelse
                aldersgrense
                arsgebyr
                url
                src {
                  childImageSharp {
                      fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
              }
            }
         }
        }
      `)

  return (
    <LayoutComponent bodyClass="page-services">
      <SeoComponent
        description="Finn de beste kredittkortene i Norge."
        title={title} />
      <div className="container pt-3">
        <div className="row justify-content-start">
          <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
            <h1>
              {title}
            </h1>
            <p>
              Se hvilke kort som er best for deg, enten du trenger å få mest mulig ut av rentefri
              lån eller ønsker å tjene penger og fordeler på dine faste utgifter.
            </p>
          </div>
          {data.data.frontmatter.image && (
            <div className="col-12 col-md-5 col-lg-6 order-1 order-md-2 position-relative">
              <img className="intro-image" src={data.data.frontmatter.image} />
            </div>
          )}
        </div>
        <ul>
          {data.allCardsJson.edges.map((it) => {
            return (
              <li
                className={'my-3 p-3'}
                style={{
                  listStyle: 'none',
                  borderRadius: '10px',
                  borderBottom: it.node.anbefalt ? 'inherit' : '1px solid #eaeffe',
                  background: it.node.anbefalt ? '#0000ff05' : 'inherit',
                }}>

                <div className={"kreditt-card"}>
                  <div className={'d-flex flex-column align-items-center'}>
                    {it.node.anbefalt &&
                    <div className="ribbon-2">
                      <span style={{ color: 'white' }} className={'pl-1'}>Anbefalt</span>
                    </div>}
                    <Img className={"kreditt-image"} fluid={it.node.src.childImageSharp.fluid} />
                  </div>
                  <div className="pl-4">
                    <div>
                      <h3 style={{ fontWeight: 'bold' }}>{it.node.navn}</h3>
                      <div className={'d-flex'}>
                        <div className="pr-2">
                          <div style={{ color: 'grey' }}>Årsavgift</div>
                          <div>
                            {it.node.arsgebyr}
                          </div>
                        </div>
                        <div className="pr-2">
                          <div style={{ color: 'grey' }}>Rente</div>
                          <div>
                            {it.node.rente} %
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="pt-2" style={{ color: 'black' }}>
                      {it.node.beskrivelse}
                    </p>
                    <div className={'d-flex'}>
                      <div className="pr-2">
                        <div style={{ color: 'grey' }}>Lånebeløp</div>
                        <NumberFormat displayType={'text'} thousandSeparator={true}
                                      value={it.node.maks} />
                      </div>
                      <div className="pr-2">
                        <div style={{ color: 'grey' }}>Aldersgrense</div>
                        {it.node.aldersgrense} år
                      </div>
                      <div className="pr-2">
                        <div style={{ color: 'grey' }}>Rentefrie dager</div>
                        {!!it.node.betalingsutsettelse ? it.node.betalingsutsettelse : 0}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="text-center pl-2">
                      <a target="__blank" href={it.node.url} className="green-button big-button">
                        Søk >
                      </a>
                      {false && <p className="annonse">Annonselenke</p>}
                    </div>
                  </div>

                </div>
                <div style={{ color: 'grey', fontStyle: 'italic' }} className={'pt-2'}>
                  {it.node.eksempel}
                </div>
              </li>
            )
          })}
        </ul>
        {<div className="justify-content-start pb-2 container">
          <div className="pt-6">
            <div dangerouslySetInnerHTML={{ __html: data.data.html }} />
          </div>
        </div>}
      </div>
    </LayoutComponent>
  )
}
export default BesteKredittkort
